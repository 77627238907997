import { render, staticRenderFns } from "./RG_ApprovalInfoDetail.vue?vue&type=template&id=7b784ddf&scoped=true"
import script from "./RG_ApprovalInfoDetail.vue?vue&type=script&lang=js"
export * from "./RG_ApprovalInfoDetail.vue?vue&type=script&lang=js"
import style0 from "./RG_ApprovalInfoDetail.vue?vue&type=style&index=0&id=7b784ddf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b784ddf",
  null
  
)

export default component.exports